"use strict";

import * as $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";

window.$ = window.jQuery = $;

$(document).ready(function () {
  function bikeWaiverSigned(signedInitials) {
    $.ajax("/waiver-signed", {
      type: "post",
      data: {
        _token: $('input[name="_token"]').val(),
        initials: signedInitials,
      },
      success: function (response) {
        return !!response.success;
      },
    });
  }

  /*
   * ####################################
   * #            Step 1                #
   * ####################################
   */
  $("#stepSubmit").on("click", function (e) {
    e.preventDefault();
    $(".field-phone").each(function () {
      $(this).blur();
    });
    $("form").submit();
  });

  /*
   * ####################################
   * #            Step 2                #
   * ####################################
   */
  $("input[name=pickup_type]").change(function () {
    let pickupName = $("#pickupFullName");
    let pickupEmail = $("#pickupEmail");
    let pickupPhone = $("#pickupPhone");

    if ($(this).val() === "Homeowner") {
      /* buyer*** is populated by the template file */
      pickupName.val(buyerName).prop("disabled", true);
      pickupEmail.val(buyerEmail).prop("disabled", true);
      pickupPhone.val(buyerPhone).prop("disabled", true);
    } else {
      pickupName.val("").prop("disabled", false);
      pickupEmail.val("").prop("disabled", false);
      pickupPhone.val("").prop("disabled", false);
    }
  });

  //Remove disabled attr from fields when No is selected
  $(".form-address-same input[type='radio']").on("change", function () {
    if ($(this).val() === "No") {
      $(".content-form-address .form-control").removeAttr("disabled");
    } else {
      $(".content-form-address .form-control").attr("disabled", "disabled");
    }
  });

  //Select bike
  let contentStepPickup = $(".content-step-2-pickup"),
    contentStepAddress = $(".content-step-2-address"),
    contentStepNoBike = $(".content-form-step-nobike");

  $(".form-pickup-bike input[type='radio']").on("change", function () {
    if ($(this).val() === "No") {
      contentStepPickup.addClass("hidden");
      contentStepAddress.addClass("hidden");
      $(this).parents(".content-form").removeClass("right-divider");
      contentStepNoBike.removeClass("hidden");
    } else {
      contentStepPickup.removeClass("hidden");
      contentStepAddress.removeClass("hidden");
      $(this).parents(".content-form").addClass("right-divider");
      contentStepNoBike.addClass("hidden");
    }
  });

  // Remove disabled so these submit.
  $(".form-step-2").submit(function () {
    $(".content-form-address .form-control").removeAttr("disabled");
    $(".form-contact-pickup .form-control").removeAttr("disabled");
  });

  /*
   * ####################################
   * #           Thank You              #
   * ####################################
   */

  // Thank-you
  $("#waiverLink").on("click", function () {
    $("#termsSign").prop("disabled", false).focus();
    $("#waiverAccept").prop("disabled", false).removeClass("disabled");
    $(".tooltip-text").remove();
  });

  $("#waiverAccept").on("click", function () {
    let initialsInput = $("#termsSign");
    let initials = initialsInput.val();
    let bikeWaiver = $(".bike-waiver");
    let thankYou = $(".thank-you");
    let thankYouBanner = $(".thank-you-banner");

    if (initials.length >= 2) {
      bikeWaiverSigned(initials);
      thankYouBanner.html(
        "CONGRATULATIONS ON YOUR NEW HOME PURCHASE AT GREAT PARK NEIGHBORHOODS!"
      );
      bikeWaiver.fadeOut();
      thankYou.delay(300).fadeIn();
    } else {
      initialsInput.css("border", "1px solid red").focus();
      if ($(".alert-danger").length === 0) {
        $(".popup-content-terms").append(
          '<div class="alert alert-danger"><ul>' +
            "<li>Please enter your initials, must be at least two letters.</li>" +
            "</ul></div>"
        );
      }
    }
  });
});
